import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {API_PORT, API_URL} from './env'

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

global.API_URL = API_URL;
global.API_PORT = API_PORT;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
