import React from 'react';
import ApiService from '../services/ApiService';

import './Login.scss';

export default class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            usernameError: false,
            password: '',
            passwordError: false,
            error: false,
            errorMessage: '',
            loadingRequest: false,
        };

        this.successRedirect = '/dashboard';

        this.api = ApiService.getInstance();

        this.checkUserLogin();

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.submitLogin = this.submitLogin.bind(this);

        // Buttons multiple click prevention
        this.loginButtonClicked = false;
    }

    checkUserLogin() {
        if (this.api.hasJWTToken() && this.api.hasUsername()) {
            this.props.history.push(this.successRedirect);
        }
    }

    handleUsernameChange(event) {
        this.setState({
            username: event.target.value,
            usernameError: event.target.value.length === 0,
        });
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value,
            passwordError: event.target.value.length === 0,
        });
    }

    successLoginFinish() {
        window.location.href = this.successRedirect;
        this.loginButtonClicked = false;
    }

    async submitLogin() {
        if (!this.loginButtonClicked) {
            this.setState({ loadingRequest: true, error: false, errorMessage: '' }, async () => {
                const response = await this.api.login(this.state.username, this.state.password);

                if (response.success) {
                    this.successLoginFinish();
                } else {
                    if (response.validError) {
                        this.setState(
                            {
                                loadingRequest: false,
                                error: true,
                                errorMessage: response.errorMessage,
                            },
                            () => (this.loginButtonClicked = false),
                        );
                    } else {
                        this.props.showAlert();
                        this.setState(
                            {
                                loadingRequest: false,
                            },
                            () => (this.loginButtonClicked = false),
                        );
                    }
                }
            });
        }
    }

    render() {
        return (
            <div className={'login-wrapper d-flex justify-content-center align-items-center'}>
                <form onSubmit={(event) => event.preventDefault()}>
                    <div className={'p-4 login-container'}>
                        <h3>Login - Car counter</h3>
                        <hr />
                        <div className={'mx-3 mb-3 px-4 d-flex flex-column'}>
                            <label>Username</label>
                            <input
                                className={'mb-3' + (this.state.usernameError ? ' error' : '')}
                                type={'text'}
                                placeholder={'Username'}
                                value={this.state.username}
                                onChange={this.handleUsernameChange}
                            />
                            <label>Password</label>
                            <input
                                className={'mb-3' + (this.state.passwordError ? ' error' : '')}
                                type={'password'}
                                placeholder={'Password'}
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                            />
                            <p className={'error-message mb-2' + (!this.state.error ? ' d-none' : '')}>
                                {this.state.errorMessage}
                            </p>
                            <button
                                className={'primary-button my-3'}
                                onClick={this.submitLogin}
                                disabled={this.state.loadingRequest}
                            >
                                {this.state.loadingRequest ? (
                                    <span
                                        className={'spinner-border spinner-border-sm align-middle me-2'}
                                        role={'status'}
                                        aria-hidden={'true'}
                                    ></span>
                                ) : null}
                                Login
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
