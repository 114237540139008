import React from 'react';
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import moment from 'moment';
import TabList from './../WebComponents/TabList/TabList';
import Table from './../WebComponents/Table/Table';
import Dropdown from './../WebComponents/Dropdown/Dropdown';
import LoadingIcon from './../WebComponents/LoadingIcon/LoadingIcon';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Modal } from 'bootstrap';
import ApiService from './../services/ApiService';
import iconClose from './../assets/icons/x.svg';
import thumbnailUnavailableImg from '../assets/thumbnail_unavailable.png';

import 'moment/locale/sk';
import 'js-datepicker/dist/datepicker.min.css';
import 'url-search-params-polyfill';
import './Dashboard.scss';
import DatePicker from '../WebComponents/DatePicker/DatePicker';

const NEW_MEASUREMENT_CREATE = 0; // inputs from user
const NEW_MEASUREMENT_CONFIRM = 1; // user check

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0, // 0 = overview, 1 = map
            awaitingFirstSelection: true,
            isLoading: true,

            measurements: [],
            selectedMesurement: '',
            measurementDataLoading: false,
            measurementThumbnail: undefined,

            data: [],

            createNewName: '',
            createNewDateFrom: null,
            createNewDateTo: null,
            createNewLatitude: null,
            createNewLongitude: null,
            createNewNote: '',
            createNewStep: NEW_MEASUREMENT_CREATE,
            createNewConfirmLoading: false,
            createNewErrorText: '',

            exportLoading: false,
        };

        this.api = ApiService.getInstance();
        this.api.setBaseUrl(global.API_URL);
        this.api.setPort(global.API_PORT);

        this.createMeasurementModal = null;
        this.createMeasurementModalRef = React.createRef();

        this.measurementThumbnailRef = React.createRef();

        this.zoom = 12.77;
        this.position = [48.1465233, 17.1104175];

        this.modalConfirmClicked = false;

        ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

        moment.locale('sk');

        this.openCreateMeasurementModal = this.openCreateMeasurementModal.bind(this);
        this.closeCreateMeasurementModal = this.closeCreateMeasurementModal.bind(this);
        this.checkCreateMeasurementModal = this.checkCreateMeasurementModal.bind(this);
        this.confirmMeasurementCreation = this.confirmMeasurementCreation.bind(this);
        this.handleExportData = this.handleExportData.bind(this);
    }

    componentDidMount() {
        if (!this.api.hasJWTToken()) {
            this.api.logout();
        } else {
            this.setState({ isLoading: true }, () => this.loadMesurements());
        }

        console.log(this.createMeasurementModalRef);
        this.createMeasurementModal = new Modal(this.createMeasurementModalRef.current, {
            backdrop: 'static',
            keyboard: false,
        });
    }

    async loadMesurements() {
        const measurementsResponse = await this.api.getMeasurements();
        console.log(measurementsResponse);

        if (measurementsResponse.success) {
            for (let measurement of measurementsResponse.data) {
                measurement.label = measurement.place;
                measurement.value = measurement.id;
            }
            this.setState({
                measurements: measurementsResponse.data,
                isLoading: false,
            });
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }

    async updateMesurementData() {
        const measurementDataResponse = await this.api.getMeasurementData(this.state.selectedMesurement.id);

        if (measurementDataResponse.success) {
            this.setState(
                {
                    data: measurementDataResponse.data,
                    isLoading: false,
                    measurementDataLoading: false,
                    measurementThumbnail: undefined,
                },
                () => {
                    this.showMeasurementThumbnail();
                },
            );
        } else {
            this.setState({
                isLoading: false,
                measurementDataLoading: false,
            });
        }
    }

    openCreateMeasurementModal() {
        this.createMeasurementModal.show();
    }

    closeCreateMeasurementModal() {
        this.createMeasurementModal.hide();
    }

    checkCreateMeasurementModal() {
        if (this.state.createNewName.length === 0 || this.state.createNewName.length >= 255) {
            this.setState({
                createNewErrorText: 'Neplatný názov',
            });
            return;
        }

        if (this.state.createNewLatitude < -90.0 || this.state.createNewLatitude >= 90) {
            this.setState({
                createNewErrorText: 'Neplatná zemepisná šírka',
            });
            return;
        }

        if (this.state.createNewLongitude < -180.0 || this.state.createNewLongitude >= 180) {
            this.setState({
                createNewErrorText: 'Neplatná zemepisná dĺžka',
            });
            return;
        }

        if (this.state.createNewDateFrom === null) {
            this.setState({
                createNewErrorText: 'Neplatný začiatok merania',
            });
            return;
        }

        if (this.state.createNewDateTo === null) {
            this.setState({
                createNewErrorText: 'Neplatný koniec merania',
            });
            return;
        }

        if (this.state.createNewNote.length >= 1023) {
            this.setState({
                createNewErrorText: 'Neplatná poznámka (príliž veľa textu)',
            });
            return;
        }

        if (this.state.createNewDateFrom.getTime() >= this.state.createNewDateTo.getTime()) {
            this.setState({
                createNewErrorText: 'Neplatný rozsah merania',
            });
            return;
        }

        this.setState({
            createNewStep: NEW_MEASUREMENT_CONFIRM,
            createNewErrorText: '',
        });
    }

    confirmMeasurementCreation() {
        if (!this.modalConfirmClicked) {
            this.modalConfirmClicked = true;
            this.setState(
                {
                    createNewConfirmLoading: true,
                },
                async () => {
                    // await for send req
                    const createMeasurementResponse = await this.api.createNewMeasurement(
                        `${this.state.createNewName} (${moment(this.state.createNewDateFrom).format(
                            'DD.MM.YYYY',
                        )} - ${moment(this.state.createNewDateTo).format('DD.MM.YYYY')})`,
                        this.state.createNewDateFrom.toISOString(),
                        this.state.createNewDateTo.toISOString(),
                        this.state.createNewLatitude.toString(),
                        this.state.createNewLongitude.toString(),
                        this.state.createNewNote,
                    );

                    if (createMeasurementResponse.success) {
                        await this.loadMesurements();
                    }

                    this.closeCreateMeasurementModal();
                    setTimeout(() => {
                        this.setState({
                            createNewName: '',
                            createNewDateFrom: null,
                            createNewDateTo: null,
                            createNewLatitude: null,
                            createNewLongitude: null,
                            createNewNote: '',
                            createNewStep: NEW_MEASUREMENT_CREATE,
                            createNewConfirmLoading: false,
                            createNewErrorText: '',
                        });
                    }, 300);
                    this.modalConfirmClicked = false;

                    if (createMeasurementResponse.success) {
                        this.props.showSuccessAlert('Úspech', 'Požiadavok na nové meranie bol úspešne vytvorený');
                    } else {
                        this.props.showAlert('Chyba', 'Nastala chyba pri vytváraní požiadavku na nové meranie');
                    }
                },
            );
        }
    }

    hideOverview() {
        if (this.state.isLoading && !this.data) {
            return 'app-overview-visibility-hidden';
        } else if (this.state.isLoading && this.data) {
            return 'app-light-opacity';
        }

        return '';
    }

    async showMeasurementThumbnail() {
        const imgObjectUrl = await this.api.getMeasurementThumbnail(this.state.selectedMesurement.id);

        // this.measurementThumbnailRef.current.src = imgObjectUrl;
        // this.measurementThumbnailRef.current.style.display = 'block';

        this.setState({
            measurementThumbnail: imgObjectUrl,
        });
    }

    handleExportData() {
        this.setState({
            exportLoading: true
        }, async () => {
            await this.api.getMeasurementDataExport(this.state.selectedMesurement.id);

            this.setState({
                exportLoading: false
            });
        })
    }

    renderCreateMeasurementModalContent() {
        if (this.state.createNewStep === NEW_MEASUREMENT_CREATE) {
            return (
                <>
                    <div className={'modal-header px-4'}>
                        <h5 className={'modal-title'}>Formulár pre vytvorenie požiadakvy nového merania</h5>
                        <div className={'circle-button'} onClick={this.closeCreateMeasurementModal}>
                            <img src={iconClose} alt={'Close icon'} />
                        </div>
                    </div>
                    <div className={'modal-body px-4'}>
                        <div className={'d-flex flex-column'}>
                            <label>Názov merania (dátumy na konci sa doplnia automaticky po vytvorení merania):</label>
                            <input
                                className={'mb-3'}
                                type={'text'}
                                placeholder={'Petržalka - Centrum'}
                                onChange={(event) => this.setState({ createNewName: event.target.value })}
                                value={this.state.createNewName}
                            />
                        </div>
                        <div className={'d-flex flex-column'}>
                            <label>Zemepisná šírka (Latitude):</label>
                            <input
                                className={'mb-3'}
                                type={'text'}
                                placeholder={'48.1439269'}
                                onChange={(event) => this.setState({ createNewLatitude: event.target.value })}
                                value={this.state.createNewLatitude}
                            />
                            <label>Zemepisná dĺžka (Longitude):</label>
                            <input
                                className={'mb-3'}
                                type={'text'}
                                placeholder={'17.1053476'}
                                onChange={(event) => this.setState({ createNewLongitude: event.target.value })}
                                value={this.state.createNewLongitude}
                            />
                        </div>
                        <div className={'d-flex flex-row pb-3'}>
                            <div className="d-flex flex-column me-3">
                                <label>Začiatok merania:</label>
                                <DatePicker
                                    selectedDate={
                                        this.state.createNewDateFrom === null ? null : this.state.createNewDateFrom
                                    }
                                    startDate={
                                        this.state.createNewDateFrom === null ? null : this.state.createNewDateFrom
                                    }
                                    endDate={this.state.createNewDateTo === null ? null : this.state.createNewDateTo}
                                    selectsStart={true}
                                    placeholder={'Vyberte dátum od...'}
                                    handleChange={(date) => {
                                        this.setState({
                                            createNewDateFrom: date,
                                        });
                                    }}
                                />
                            </div>
                            <div className="d-flex flex-column ms-3">
                                <label>Koniec merania:</label>
                                <DatePicker
                                    selectedDate={
                                        this.state.createNewDateTo === null ? null : this.state.createNewDateTo
                                    }
                                    startDate={
                                        this.state.createNewDateFrom === null ? null : this.state.createNewDateFrom
                                    }
                                    endDate={this.state.createNewDateTo === null ? null : this.state.createNewDateTo}
                                    minDate={
                                        this.state.createNewDateFrom === null ? null : this.state.createNewDateFrom
                                    }
                                    placeholder={'Vyberte dátum do...'}
                                    selectsEnd={true}
                                    handleChange={(date) => {
                                        this.setState({
                                            createNewDateTo: date,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className={'d-flex flex-column'}>
                            <label>Poznámka a číslo vozíka:</label>
                            <textarea
                                className={'mb-3'}
                                type={'text'}
                                placeholder={'rgb1'}
                                rows={3}
                                value={this.state.createNewNote}
                                onChange={(event) =>
                                    this.setState({
                                        createNewNote: event.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className={'d-flex flex-column'}>
                            <p className="error-text text-center">{this.state.createNewErrorText}</p>
                        </div>
                    </div>
                    <div className={'modal-footer'}>
                        <button
                            type={'button'}
                            className={'secondary-button'}
                            onClick={this.closeCreateMeasurementModal}
                        >
                            Zrušiť
                        </button>
                        <button type={'button'} className={'primary-button'} onClick={this.checkCreateMeasurementModal}>
                            Ďalej
                        </button>
                    </div>
                </>
            );
        } else if (this.state.createNewStep === NEW_MEASUREMENT_CONFIRM) {
            return (
                <>
                    <div className={'modal-header px-4'}>
                        <h5 className={'modal-title'}>Formulár pre vytvorenie požiadakvu nového merania</h5>
                        <div className={'circle-button'} onClick={this.closeCreateMeasurementModal}>
                            <img src={iconClose} alt={'Close icon'} />
                        </div>
                    </div>
                    <div className={'modal-body px-4'}>
                        <div className={'d-flex flex-column'}>
                            <div className="d-flex flex-row overflow-auto">
                                <p className="me-1">{'Názov merania: '}</p>
                                <b>{`${this.state.createNewName} (${moment(this.state.createNewDateFrom).format(
                                    'DD.MM.YYYY',
                                )} - ${moment(this.state.createNewDateTo).format('DD.MM.YYYY')})`}</b>
                            </div>
                            <div className="d-flex flex-row overflow-auto">
                                <p className="me-1">{'Zemepisná šírka: '}</p>
                                <b>{this.state.createNewLatitude}</b>
                            </div>
                            <div className="d-flex flex-row overflow-auto">
                                <p className="me-1">{'Zemepisná dĺžka: '}</p>
                                <b>{this.state.createNewLongitude}</b>
                            </div>
                            <div className="d-flex flex-row overflow-auto">
                                <p className="me-1">{'Začiatok merania: '}</p>
                                <b>{moment(this.state.createNewDateFrom).format('LL')}</b>
                            </div>
                            <div className="d-flex flex-row overflow-auto">
                                <p className="me-1">{'Koniec merania: '}</p>
                                <b>{moment(this.state.createNewDateTo).format('LL')}</b>
                            </div>
                            <div className="d-flex flex-row overflow-auto">
                                <p className="me-1">{'Poznámka: '}</p>
                                <b>{this.state.createNewNote}</b>
                            </div>
                        </div>
                    </div>
                    <div className={'modal-footer'}>
                        <button
                            type={'button'}
                            className={'secondary-button'}
                            onClick={() => {
                                this.setState({ createNewStep: NEW_MEASUREMENT_CREATE });
                            }}
                        >
                            Späť
                        </button>
                        <button
                            type={'button'}
                            className={'primary-button' + (this.state.createNewConfirmLoading ? ' disabled' : '')}
                            onClick={this.confirmMeasurementCreation}
                        >
                            {this.state.createNewConfirmLoading ? 'Odosiela sa...' : 'Potvrdiť a odoslať'}
                        </button>
                    </div>
                </>
            );
        }
    }

    renderCreateMeasurementModal() {
        return (
            <div className={'modal modal-lg fade'} ref={this.createMeasurementModalRef} tabIndex={'-1'} role={'dialog'}>
                <div className={'modal-dialog'}>
                    <div className={'modal-content'}>{this.renderCreateMeasurementModalContent()}</div>
                </div>
            </div>
        );
    }

    renderMeasurementDropdown() {
        return (
            <div className="d-flex flex-row">
                <Dropdown
                    className={'measurements-dropdown'}
                    label={'Meranie'}
                    placeholder={'Výber merania...'}
                    options={this.state.measurements}
                    value={this.state.selectedMesurement}
                    handleChange={(option) => {
                        this.setState(
                            {
                                selectedMesurement: option,
                                awaitingFirstSelection: false,
                                measurementDataLoading: true,
                            },
                            () => this.updateMesurementData(),
                        );
                    }}
                />
                <button
                    className={'primary-button create-measurement-button'}
                    onClick={this.openCreateMeasurementModal}
                >
                    Vytvoriť nové meranie
                </button>
                <p className='logout-text ms-2 sim-nav-link nav-link' onClick={() => this.api.logout()} >Odhlásiť sa</p>
            </div>
        );
    }

    renderTabListContent() {
        if (this.state.activeTab === 0) {
            return this.renderOverview();
        } else if (this.state.activeTab === 1) {
            return this.renderMap();
        }
    }

    renderBarChart(data) {
        const labels = [];
        const smallCars = [];
        const bigCars = [];

        const options = {
            plugins: {
                title: {
                    display: true,
                    text: 'Počet vozidiel',
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
        };

        for (let row of data) {
            labels.push(row.hourFormated);
            smallCars.push(row.smallCars);
            bigCars.push(row.bigCars);
        }

        return (
            <Bar
                className="bar-chart"
                options={options}
                data={{
                    labels: labels,
                    datasets: [
                        { label: 'Osobné vozidlá', data: smallCars, backgroundColor: '#5684F8' },
                        { label: 'Ostatné druhy vozidiel', data: bigCars, backgroundColor: '#FFBD33' },
                    ],
                }}
            />
        );
    }

    renderOverview() {
        if (this.state.awaitingFirstSelection) {
            return <p className={'awaiting-selection-text'}>Prosím, vyberte meranie</p>;
        } else {
            if (this.state.measurementDataLoading) {
                return (
                    <div className={''}>
                        <LoadingIcon size={54} className={'app-content-loading mesurement-data-loading'} />
                    </div>
                );
            } else {
                return (
                    <div className={'relative'}>
                        <img
                            className={'mb-4 m-auto'}
                            width={640}
                            height={360}
                            ref={this.measurementThumbnailRef}
                            style={{
                                display: typeof this.state.measurementThumbnail === 'undefined' ? 'none' : 'block',
                            }}
                            src={this.state.measurementThumbnail}
                        />
                        <hr
                            style={{
                                display: typeof this.state.measurementThumbnail === 'undefined' ? 'none' : 'block',
                            }}
                        />
                        <button className='primary-button' onClick={this.handleExportData} disabled={this.exportLoading}>Exportovať do CSV</button>
                        {this.state.data.map((singleData) => (
                            <div key={singleData.day}>
                                <h3 className={'day-title'}>{singleData.dayFormated}</h3>
                                <p>
                                    {'Počet osobných vozidiel: '}
                                    <b>{singleData.totalSmallCars.toLocaleString()}</b>
                                </p>
                                <p>
                                    {'Počet ostatných druhov vozidiel: '}
                                    <b>{singleData.totalBigCars.toLocaleString()}</b>
                                </p>
                                <div className="data-table">
                                    <Table
                                        className={this.hideOverview()}
                                        clickableRows={true}
                                        columns={[
                                            {
                                                name: 'Čas',
                                                selector: 'hourFormated',
                                                sortable: true,
                                                center: true,
                                                width: '170px',
                                            },
                                            {
                                                name: 'Osobných vozidiel',
                                                selector: 'smallCars',
                                                sortable: true,
                                                center: true,
                                            },
                                            {
                                                name: 'Ostatné druhy vozidiel',
                                                selector: 'bigCars',
                                                sortable: true,
                                                center: true,
                                            },
                                        ]}
                                        data={singleData.hours}
                                    />
                                    <div className={'bar-wrapper'}>{this.renderBarChart(singleData.hours)}</div>
                                </div>
                                {singleData.day !== this.state.data[this.state.data.length - 1].day ? <hr /> : null}
                            </div>
                        ))}
                        <LoadingIcon
                            size={54}
                            className={'app-content-loading' + (!this.state.isLoading ? ' hidden' : '')}
                        />
                    </div>
                );
            }
        }
    }

    renderMap() {
        return (
            <div className={'relative-map'}>
                <div className={this.state.isLoading ? 'app-light-opacity-map' : 'app-full-opacity-map'}>
                    <MapContainer id={'map'} center={this.position} zoom={this.zoom} zoomControl={false}>
                        <TileLayer
                            url="https://api.maptiler.com/maps/basic/{z}/{x}/{y}@1x.png?key=xlDIp3546qs9EP9CMhi3"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            tileSize={512}
                            zoomOffset={-1}
                            minZoom={1}
                        />
                        {this.state.measurements.map((item) => (
                            <Marker key={item.id} position={[item.latitude, item.longitude]}>
                                <Popup>
                                    <p>{item.place}</p>
                                    <a
                                        onClick={() => {
                                            this.setState(
                                                {
                                                    activeTab: 0,
                                                    selectedMesurement: item,
                                                    awaitingFirstSelection: false,
                                                    measurementDataLoading: true,
                                                },
                                                () => this.updateMesurementData(),
                                            );
                                        }}
                                        href="#"
                                    >
                                        Detaily merania
                                    </a>
                                </Popup>
                            </Marker>
                        ))}
                    </MapContainer>
                </div>
                <LoadingIcon size={54} className={'app-content-loading' + (!this.state.isLoading ? ' hidden' : '')} />
            </div>
        );
    }

    render() {
        return (
            <div className={'container'}>
                {this.renderCreateMeasurementModal()}
                <div className={'app-wrapper'}>
                    <div className={'app-filter'}></div>
                    {this.renderMeasurementDropdown()}
                    <TabList
                        activeTab={this.state.activeTab}
                        onActiveTabChanged={(index) => this.setState({ activeTab: index })}
                        tabs={[
                            {
                                name: 'Prehľad',
                                content: null,
                            },
                            {
                                name: 'Mapa',
                                content: null,
                            },
                        ]}
                    />
                </div>

                {this.renderTabListContent()}
            </div>
        );
    }
}
