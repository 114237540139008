/**
 * @author Dominik Klement
 */
import React from "react";
import * as PropTypes from "prop-types";
import DataTable from 'react-data-table-component';

import "./Table.scss";

export default class Table extends React.Component {

    constructor(props) {
        super(props);

        if(this.props.columns) {
            for(let i = 0; i < this.props.columns.length; i++) {
                this.props.columns[i].center = true;
            }
        }
    }

    render() {
        return <div className={"data-table" + (this.props.clickableRows ? " clickable": "") +
                                (this.props.className ? " " + this.props.className : "")}
                    style={this.props.style}>

            <DataTable
                onRowClicked={this.props.onRowClicked || function(){}}
                noHeader
                onSort={this.props.handleSort}
                defaultSortField={this.props.orderBy}
                defaultSortAsc={this.props.orderAsc}
                columns={this.props.columns}
                data={this.props.data}
            />
        </div>;
    }
}

Table.propTypes = {
    className: PropTypes.string,
    style: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),

    columns: PropTypes.array,
    data: PropTypes.array,
    orderBy: PropTypes.string,
    orderAsc: PropTypes.bool,
    clickableRows: PropTypes.bool,
    handleSort: PropTypes.func,

    onRowClicked: PropTypes.func
};