import React from 'react';
import { Toast } from 'bootstrap';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import Error404 from './Components/Error404';
import iconError from './assets/icons/error.svg';
import iconSuccess from './assets/icons/check-success.svg';

import ApiService from './services/ApiService';

import './App.scss';

const ALERT_SHOW_TIME = 16000;

export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alertTitle: 'Oops, this should not happen',
            alertContent: 'Error while communicating with server.',

            successAlertTitle: 'Success',
            successAlertContent: 'Message successfully sent',
        };

        this.api = ApiService.getInstance();
        this.api.setBaseUrl(global.API_URL);
        this.api.setPort(global.API_PORT);

        this.toastAlertRef = React.createRef();
        this.toastSuccessAlertRef = React.createRef();

        this.toastTimeout = null;
        this.toastSuccessTimeout = null;

        this.showAlert = this.showAlert.bind(this);
        this.hideAlert = this.hideAlert.bind(this);

        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.hideSuccessAlert = this.hideSuccessAlert.bind(this);
    }

    componentDidMount() {
        this.toastAlert = new Toast(this.toastAlertRef.current, {
            delay: ALERT_SHOW_TIME,
        });

        this.toastSuccessAlert = new Toast(this.toastSuccessAlertRef.current, {
            delay: ALERT_SHOW_TIME,
        });
    }

    showAlert(alertTitle = 'Oops, this should not happen', alertContent = 'Error while communicating with server.') {
        this.setState(
            {
                alertTitle: alertTitle,
                alertContent: alertContent,
            },
            () => {
                if (this.toastTimeout !== null) {
                    clearTimeout(this.toastTimeout);
                }
                this.toastAlert.show();
                this.toastTimeout = setTimeout(() => {
                    this.toastAlert.hide();
                }, ALERT_SHOW_TIME);
            },
        );
    }

    hideAlert() {
        clearTimeout(this.toastTimeout);
        this.toastTimeout = null;
        this.toastAlert.hide();
    }

    showSuccessAlert(alertTitle = 'Success', alertContent = '') {
        this.setState(
            {
                successAlertTitle: alertTitle,
                successAlertContent: alertContent,
            },
            () => {
                if (this.toastSuccessTimeout !== null) {
                    clearTimeout(this.toastSuccessTimeout);
                }
                this.toastSuccessAlert.show();
                this.toastSuccessTimeout = setTimeout(() => {
                    this.toastSuccessAlert.hide();
                }, ALERT_SHOW_TIME);
            },
        );
    }

    hideSuccessAlert() {
        clearTimeout(this.toastSuccessTimeout);
        this.toastSuccessTimeout = null;
        this.toastSuccessAlert.hide();
    }

    renderSuccessToast() {
        return (
            <div className={'position-fixed top-0 end-0 p-3'} style={{ zIndex: 11 }}>
                <div ref={this.toastSuccessAlertRef} className={'toast hide'} role={'alert'}>
                    <div className={'toast-header'}>
                        <img className={'me-1'} src={iconSuccess} alt={'success'} />
                        <strong className={'me-auto'}>{this.state.successAlertTitle}</strong>
                        <button type={'button'} className={'btn-close'} onClick={this.hideSuccessAlert}></button>
                    </div>
                    <div className={'toast-body'}>{this.state.successAlertContent}</div>
                </div>
            </div>
        );
    }

    renderAlertToast() {
        return (
            <div className={'position-fixed top-0 end-0 p-3'} style={{ zIndex: 11 }}>
                <div ref={this.toastAlertRef} className={'toast hide'} role={'alert'}>
                    <div className={'toast-header'}>
                        <img className={'me-1'} src={iconError} alt={'error'} />
                        <strong className={'me-auto'}>{this.state.alertTitle}</strong>
                        <button type={'button'} className={'btn-close'} onClick={this.hideAlert}></button>
                    </div>
                    <div className={'toast-body'}>{this.state.alertContent}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                {this.renderAlertToast()}
                {this.renderSuccessToast()}
                <BrowserRouter>
                    <Switch>
                        <Route
                            exact
                            path={'/'}
                            render={({ history }) => <Login history={history} showAlert={this.showAlert} />}
                        ></Route>
                        <Route
                            exact
                            path={'/dashboard'}
                            render={({ history }) => (
                                <Dashboard
                                    history={history}
                                    showAlert={this.showAlert}
                                    showSuccessAlert={this.showSuccessAlert}
                                />
                            )}
                        ></Route>
                        <Route path={'*'}>
                            <Error404 />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </>
        );
    }
}
