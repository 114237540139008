/**
 * @author Tomáš Hladký
 */

import React from "react";
import * as PropTypes from "prop-types";

import "./Icon.scss";

export default class Icon extends React.Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <i className={(this.props.className || "") + " icon mdi mdi-" + this.props.icon} style={this.props.style} ref={this.props.innerRef}/>
        );
    }
}

Icon.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    innerRef: PropTypes.any
};