/**
 * @author Tomáš Hladký
 */

import React from "react";
import * as PropTypes from "prop-types";

import "./LoadingIcon.scss";

export default class LoadingIcon extends React.Component {
    constructor(props) {
        super(props);
    }

    fillColor() {
        if (typeof this.props.color !== "undefined" && this.props.color !== false) {
            return this.props.color
        }
        else {
            return null;
        }
    }

    loadingIconClass() {
        if (typeof this.props.color !== "undefined" && this.props.color !== false) {
            return null;
        }
        else {
            return "loading-icon";
        }
    }

    render() {
        return (
            <div className={this.props.className} style={this.props.style}>
                <svg width={this.props.size} fill={this.fillColor()} className={this.loadingIconClass()} viewBox="0 0 50 50">
                    <path
                        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                        transform="rotate(174.246 25 25)">
                        <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25"
                                          to="360 25 25" dur="1s"
                                          repeatCount="indefinite"/>
                    </path>
                </svg>
            </div>
        );
    }
}

LoadingIcon.propTypes = {
    className: PropTypes.string,
    style: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),

    size: PropTypes.number,

    //optional
    color: PropTypes.string // (#FFFFFF)
};
