/**
 * @author Dominik Klement
 */
import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";

import "./TabList.scss";

export default class TabList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            content: (this.props.tabs.hasOwnProperty("length") && this.props.tabs[this.props.activeTab || 0]) ?
                (this.props.tabs[this.props.activeTab || 0].content || null) : null,

            activeTabIndex: this.props.activeTab || 0
        };

        this.activeBottomBorderRef = React.createRef();
        this.tabsRef = React.createRef();
        this.tabListRef = React.createRef();
        this.tabContentRef = React.createRef();

        this.onTabListClick = this.onTabListClick.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    componentDidMount() {
        if(typeof this.tabsRef.current === "object" && this.props.tabs.length && this.props.tabs[0].hasOwnProperty("content")) {
            this.onTabListClick({target: this.tabListRef.current.childNodes[0]}, this.props.tabs[0].content, 0);
        }

        if(this.props.autoResizeTabContentHeight && this.tabContentRef.current) {
            this.tabContentRef.current.style.height = (window.innerHeight - this.tabContentRef.current.getBoundingClientRect().top) + "px";
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.activeTab !== this.props.activeTab) {

        }
        if(!_.isEqual(prevProps, this.props)) {
            let activeTabIndex = this.props.activeTab;
            this.onTabListClick(
                {target: this.tabListRef.current.childNodes[activeTabIndex]},
                this.props.tabs[activeTabIndex].content,
                activeTabIndex,
                false
            );
        }
    }

    onTabListClick(e, content, index, callEvent=true) {
        let item = e.target;
        if(this.tabListRef.current) {
            let tabs = this.tabListRef.current.childNodes;

            for(let i = 0; i < tabs.length; i++) {
                if(tabs[i] !== item) {
                    tabs[i].classList.remove("active");
                }
            }

            item.classList.add("active");

            if(this.activeBottomBorderRef.current && this.tabsRef.current) {
                this.activeBottomBorderRef.current.style.width = item.clientWidth + 'px';
                this.activeBottomBorderRef.current.style.left = item.offsetLeft + 'px';
            }

            this.setState({
                content: content,
                activeTabIndex: index
            });

            if(callEvent) (this.props.onActiveTabChanged || function(){})(parseInt(index));
        }
    }

    renderTab(name, content, index) {
        return <li key={index} onClick={(e) => {this.onTabListClick(e, content, index)}}>{name}</li>;
    }

    renderContent() {
        return this.state.content;
    }

    render() {
        return <div className={"tab-list-container" + (this.props.className ? " " + this.props.className : "")}
                    style={this.props.style}>

            <div className={"tabs"} ref={this.tabsRef}>
                <div ref={this.activeBottomBorderRef} className={"active-border"} />
                <ul className={"tab-list"} ref={this.tabListRef}>
                    {Object.entries(this.props.tabs).map(([index, o]) => {
                        if(o.hasOwnProperty("name") && typeof o.name === "string" &&
                            o.hasOwnProperty("content") && typeof o.content === "object") {

                            return this.renderTab(o.name, o.content, index);
                        }

                        console.log("Wrong tab item object.");
                        return "";
                    })}
                </ul>
            </div>

            <div className={"tablist-content"} ref={this.tabContentRef}>
                {this.renderContent()}
            </div>

        </div>;
    }
}

TabList.propTypes = {
    className: PropTypes.string,
    style: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),

    tabs: PropTypes.array, // {name: String, content: React.Component}
    activeTab: PropTypes.number, // 0 - n-1 for n tabs

    onActiveTabChanged: PropTypes.func,

    autoResizeTabContentHeight: PropTypes.bool
};

TabList.defaultProps = {
    autoResizeTabContentHeight: true
};