/**
 * @author Tomáš Hladký
 */
import React from "react";
import * as PropTypes from "prop-types";
import "./Dropdown.scss";
import Icon from "../Icon/Icon";
import Select from 'react-select';

//In this case, border color must be defined here. Because of react-select default css styling
const style = {
    control: (provided) => ({
        ...provided,
        borderColor: "#DDE2EC",
        ':hover': {
            ...provided[':hover'],
            borderColor: "#c9ced7"
        }
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: 2
    }),
};

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
    }

    renderNameLabel() {
        if (typeof this.props.label !== "undefined" && this.props.label !== false) {
            return <label className={"input-label"}>{this.props.label}</label>;
        } else {
            return null;
        }
    }

    renderMessageLabel() {
        if (typeof this.props.errorMessage !== "undefined" && this.props.errorMessage !== false) {
            return <label className={"input-message"}>{this.props.errorMessage}</label>;
        } else {
            return <label className={"input-message empty"}>{this.props.errorMessage}</label>;
        }
    }

    renderLeftIcon() {
        if (typeof this.props.leftIcon !== "undefined" && this.props.leftIcon !== false) {
            if (typeof this.props.label !== "undefined" && this.props.label !== false) {
                return <Icon icon={this.props.leftIcon} style={{top: "34px"}} className={"dropdown-left-icon"}/>;
            } else {
                return <Icon icon={this.props.leftIcon} style={{top: "14px"}} className={"dropdown-left-icon"}/>;
            }
        } else {
            return null;
        }
    }

    renderChevronIcon(){
        return null;
    }

    leftIconClass() {
        if (typeof this.props.leftIcon !== "undefined" && this.props.leftIcon !== false) {
            return " has-left-icon"
        } else {
            return "";
        }
    }

    error() {
        if (this.props.error) {
            return " error";
        } else {
            return "";
        }
    }

    onSelect(option) {
        this.props.handleChange(option);
    }

    render() {
        return (
            <div className={"relative" + (this.props.className ? " " + this.props.className : "")}
                 style={this.props.style}>
                {this.renderNameLabel()}
                <Select placeholder={this.props.placeholder} value={this.props.value} onChange={this.onSelect}
                        isDisabled={this.props.isDisabled}
                        isSearchable={this.props.isSearchable} options={this.props.options}
                        className={"select-container" + this.error() + this.leftIconClass()} classNamePrefix="select"
                        styles={style}/>
                {this.renderLeftIcon()}
                {this.renderChevronIcon()}
                {this.renderMessageLabel()}
            </div>
        );
    }
}
Dropdown.propTypes = {
    className: PropTypes.string,
    style: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),

    label: PropTypes.string,

    leftIcon: PropTypes.string,

    error: PropTypes.bool,
    errorMessage: PropTypes.string,

    isDisabled: PropTypes.bool,
    isSearchable: PropTypes.bool,

    // value: PropTypes.object,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func
};