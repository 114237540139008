import { ApiResponse, JwtDecodeData } from './api.types';
import jwtDecode from 'jwt-decode';
import { Moment } from 'moment';
import download from 'downloadjs';

export default class ApiService {
    public static instance: ApiService;

    private API_BASE_URL: string;
    private PORT: number;

    private readonly JWT_TOKEN_KEY = 'auth_token';
    private readonly USERNAME_KEY = 'username';

    constructor() {
        this.API_BASE_URL = '';
        this.PORT = 0;
    }

    static getInstance(): ApiService {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService();
        }

        return ApiService.instance;
    }

    getBaseUrl() {
        return this.API_BASE_URL;
    }

    getPort() {
        return this.PORT;
    }

    setBaseUrl(apiBaseUrl: string) {
        this.API_BASE_URL = apiBaseUrl;
    }

    setPort(port: number) {
        this.PORT = port;
    }

    /**
     * Login user, /api/login
     * Loggin spectator here will return error response
     * @param username
     * @param password
     * @returns - response data
     */
    async login(username: string, password: string) {
        const data = new URLSearchParams();
        data.append('username', username);
        data.append('password', password);

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(null);
            } else {
                if (response.status === 401) {
                    return this.constructValidErrorResponse('Invalid username or password');
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getMeasurements() {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/get/measurements`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.measurements);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getMeasurementData(measurementId: number) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('measurementId', JSON.stringify(measurementId));

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/get/measurement_data`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.measureData);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getMeasurementDataExport(measurementId: number) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('measurementId', JSON.stringify(measurementId));

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/get/export_measurement_data`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/csv',
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const blobResponse = await response.blob();
                download(blobResponse, `export.csv`);

                return;
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async createNewMeasurement(name: string, timeFrom: string, timeTo: string, latitude: string, longitude: string, note: string) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('name', name);
        data.append('timeFrom', timeFrom);
        data.append('timeTo', timeTo);
        data.append('latitude', latitude);
        data.append('longitude', longitude);
        data.append('note', note);

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/create/new_measurement`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.measureData);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getMeasurementThumbnail(measurementId: number) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        try {
            const response = await fetch(
                `${this.API_BASE_URL}:${this.PORT}/api/get/measurement_image/${measurementId}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    },
                },
            );

            if (response.ok) {
                const imgBlob = await response.blob();
                const imgObjectUrl = URL.createObjectURL(imgBlob);

                return imgObjectUrl;
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return undefined;
                }
            }
        } catch (e) {
            return undefined;
        }
    }

    storeJWTTokenData(token: string, username: string) {
        localStorage.setItem(this.JWT_TOKEN_KEY, token);
        localStorage.setItem(this.USERNAME_KEY, username);
    }

    logout() {
        this.removeJWTTokenDataData();
        window.location.href = '/';
    }

    hasJWTToken() {
        return localStorage.getItem(this.JWT_TOKEN_KEY) !== null;
    }

    hasUsername() {
        return localStorage.getItem(this.USERNAME_KEY) !== null;
    }

    getJWTToken() {
        const token = localStorage.getItem(this.JWT_TOKEN_KEY);
        if (token === null) {
            this.logout();
        } else {
            return token;
        }
    }

    getUsername() {
        const username = localStorage.getItem(this.USERNAME_KEY);
        if (username === null) {
            this.logout();
        } else {
            return username;
        }
    }

    removeJWTTokenDataData() {
        localStorage.removeItem(this.JWT_TOKEN_KEY);
        localStorage.removeItem(this.USERNAME_KEY);
    }

    constructorSucessResponse(data: any): ApiResponse {
        return {
            success: true,
            data: data,
        };
    }

    constructValidErrorResponse(errorMessage: string): ApiResponse {
        return {
            success: false,
            validError: true,
            errorMessage: errorMessage,
        };
    }

    constructUnknownErrorResponse(): ApiResponse {
        return {
            success: false,
            validError: false,
            errorMessage: 'Error while communicating with server',
        };
    }
}
